import React from 'react'

import { graphql, PageProps } from 'gatsby'
import { Link } from '../components/link'
import Layout from '../layouts/'
import { SEO } from '../components/seo'
import { cleanFileType } from '../utils/helpers'
import { ChatBubbleOvalLeftEllipsisIcon, DocumentArrowDownIcon, LinkIcon } from '@heroicons/react/24/outline'
import { useMediaQuery } from 'react-responsive'

export default function AdmissionsDocs(props: PageProps) {
  const admissionData = props.data.admissionReports.edges
  const admissionsPage = props.data.admissionsPage
  
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  })

  return (
    <Layout
      location={props.location}
      heros={admissionsPage.hero}
      herosFallbackTitle={admissionsPage.title}
    >
      <div className="container flex flex-col mx-auto mt-12 mb-20">
        <div
          dangerouslySetInnerHTML={{
            __html: admissionsPage.body?.childMarkdownRemark?.html
          }}
          className="text-lg ipsum-text text-gray-400"
        />

        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {admissionData.length > 0 ? (
              <>
                {isMobile ? (
                  <div>
                    {admissionData.map((result) => {
                      return (
                        <div
                          key={result.node.date}
                          className="w-full my-3 px-6 py-3 bg-gray-100 dark:bg-gray-900"
                        >
                          <div className="flex py-2">
                            <span className="w-20 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                              Title
                            </span>
                            <div className="text-sm text-gray-900 dark:text-gray-200">
                              {result.node.title}
                            </div>
                          </div>
                          <div className="flex py-2">
                            <span className="w-20 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                              Type
                            </span>
                            {result.node.report ? (
                              <Link
                                to={result.node.report.file.url}
                                className="flex items-center font-medium uppercase hover:underline"
                              >
                                <DocumentArrowDownIcon
                                  className="w-6 h-6 mr-1 -ml-1 opacity-50"
                                  aria-hidden="true"
                                />{' '}
                                {cleanFileType(result.node.report.file.type)}
                              </Link>
                            ) : (
                              <Link
                                to={result.node.webpage}
                                className="flex items-center font-medium hover:underline"
                              >
                                <LinkIcon
                                  className="w-6 h-6 mr-1 -ml-1 opacity-50"
                                  aria-hidden="true"
                                />{' '}
                                Website
                              </Link>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div className="overflow-hidden border-b border-gray-200 shadow dark:border-gray-800 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-100 dark:bg-gray-800">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            View
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {admissionData.map((result) => (
                          <tr key={result.node.date}>
                            <td className="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                              {result.node.title}
                            </td>
                            {result.node.report?.file?.type ? (
                              <td className="px-6 py-4 uppercase text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                                {cleanFileType(result.node.report.file.type)}
                              </td>
                            ) : (
                              <td className="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                                Website
                              </td>
                            )}
                            <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-200 whitespace-nowrap">
                              {result.node.report ? (
                                <Link
                                  to={result.node.report.file.url}
                                  className="flex items-center font-medium hover:underline"
                                >
                                  <DocumentArrowDownIcon
                                    className="w-6 h-6 mr-1 opacity-50"
                                    aria-hidden="true"
                                  />{' '}
                                  View
                                </Link>
                              ) : (
                                <Link
                                  to={result.node.webpage}
                                  className="flex items-center font-medium hover:underline"
                                >
                                  <LinkIcon
                                    className="w-6 h-6 mr-1 opacity-50"
                                    aria-hidden="true"
                                  />{' '}
                                  View
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            ) : (
              <>
                {admissionsPage.noResults ? (
                  <div className=" items-center flex justify-center flex-col py-20 rounded bg-gray-100 dark:bg-gray-900 mx-auto text-lg text-black dark:text-white">
                    <ChatBubbleOvalLeftEllipsisIcon className="w-12 h-12 text-gray-500" />
                    <h6 className="flex justify-center mx-auto text-md text-gray-500">
                      {admissionsPage.noResults.noResults}
                    </h6>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    admissionReports: allContentfulFinancialReport(
      filter: { type: { eq: "Admission" }, shown: { eq: true } }
      sort: { fields: [sticky, publishedDate], order: [DESC, DESC] }
    ) {
      edges {
        node {
          title
          date: publishedDate
          type
          webpage
          report: file {
            file {
              type: contentType
              url
            }
          }
        }
      }
    }
    admissionsPage: contentfulCustomPage(slug: { eq: "admissions" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      noResults {
        noResults
      }
      hero {
        ...HeroFragment
      }
    }
  }
`
